import React from 'react'

export default ({ children }) => (
	<div className="content-w">
        <div className="content-i">

            	{children}

        </div>
    </div>
)
